import { $themeBreakpoints } from '@themeConfig'
import firebase from 'firebase'
import axios from '@/libs/axios'
// import axios from '@/axionsConfig/httpClient'
// import router from '@/router'
// import ability from '@/libs/acl'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    mainAppURL: process.env.VUE_APP_BASE_URL,
    user: JSON.parse(localStorage.getItem('userData')) || {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    updateUser(state, val) {
      state.user = val
    },
    clearUser() {
      localStorage.setItem('userData', JSON.stringify({}))
    },
  },
  actions: {
    async login({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(data.email, data.pass)
          .then(firbaseResp => {
            if (firbaseResp.user !== undefined) {
              axios
                .get(
                  `${state.mainAppURL}/users/get_user_by_firebase_uid/${
                    firebase.auth().currentUser.uid
                  }`,
                )
                .then(resp => {
                  const userData = resp.data
                  localStorage.setItem('userData', JSON.stringify(userData))
                  commit('updateUser', userData)
                  resolve(resp)
                })
                .catch(err => {
                  dispatch('logout')
                  reject(err)
                })
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    async fetchUserFromServer({ state, commit }) {
      const resp = await axios.get(
        `${state.mainAppURL}/users/get_user_by_firebase_uid/${
          firebase.auth().currentUser.uid
        }`,
      )
      const userData = resp.data
      localStorage.setItem('userData', JSON.stringify(userData))
      // ability.update(userData.ability)
      commit('updateUser', userData)
    },
    async logout({ commit }) {
      commit('clearUser')
      await firebase.auth().signOut()
      // await router.push('/login')
    },
  },
}
