// import * as firebase from 'firebase/app'
// import 'firebase/analytics'
// import 'firebase/auth'

import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyA-qOLY6Ny3bwiKmNilF1wdRsGnbIrrHgo',
  authDomain: 'artmafia-stagging.firebaseapp.com',
  projectId: 'artmafia-stagging',
  storageBucket: 'artmafia-stagging.appspot.com',
  messagingSenderId: '721128531444',
  appId: '1:721128531444:web:93a05341af411ed90d85a1',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => new Promise((resolve, reject) => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe()
    resolve(user)
  }, reject)
})

export default firebase
