import Vue from 'vue'

// axios
import axios from 'axios'
import firebase from 'firebase'
// import router from '@/router'
// import httpClient from '@/axionsConfig/httpClient'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

axiosIns.interceptors.request.use(async config => {
  const user = await firebase.auth().currentUser
  // if (
  //   !user
  //       && router.currentRoute.value
  //       && router.currentRoute.value.meta
  //       && router.currentRoute.value.meta.requiresAuth
  // ) {
  //   await router.push('/login')
  // }
  if (config.headers != null && config.headers['X-Requested-With'] == null) {
    if (user) {
      const freshToken = await user.getIdToken()
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${freshToken}`,
        ...config.headers,
      }
    }
  }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
