import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase'
import firebase from '@/firebaseInit'
import store from '@/store/index'

// import { getAuth } from 'firebase/auth'
/* eslint-disable */
Vue.use(Router);

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        authRequired: true,
        pageTitle: "Dashboard",
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/Users.vue"),
      meta: {
        authRequired: true,
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/view-user/:id",
      name: "view-user",
      component: () => import("../components/Users/ViewUser/UsersView"),
      meta: {
        authRequired: true,
        pageTitle: "View User",
        breadcrumb: [
          {
            text: "View User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/artworks",
      name: "artworks",
      component: () => import("@/views/ArtWorks.vue"),
      meta: {
        authRequired: true,
        pageTitle: "Art Works",
        breadcrumb: [
          {
            text: "Art Works",
            active: true,
          },
        ],
      },
    },
    {
      path: "/second-page",
      name: "second-page",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        authRequired: true,
        pageTitle: "Second Page",
        breadcrumb: [
          {
            text: "Second Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register-creator",
      name: "register-creator",
      component: () => import("@/views/PublicRegisterCreator"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (
      (await firebase.getCurrentUser()) !== null &&
      store.state.app.user !== {} &&
      store.state.app.user.userRoles.role === "ROLE_ADMIN"
    ) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
  // next()
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
